import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared.module';
import { RandomEnquiryComponent } from './random-enquiry.component';



@NgModule({
  declarations: [
    RandomEnquiryComponent
  ],
  imports: [
    CommonModule, 
    MatDialogModule,
    MatButtonModule,   
    MatFormFieldModule,
    MatInputModule,  
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule, 
    SharedModule
  
     
  ],
  exports:[RandomEnquiryComponent]
})
export class RandomEnquiryModule { }
