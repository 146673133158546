import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';  
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'; 
import { ServiceFactory } from 'src/app/services/factory/service-factory.service';
import { DataFactoryService } from 'src/app/services/factory/data-factory.service';
import { finalize } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-random-enquiry',
  templateUrl: './random-enquiry.component.html',
  styleUrls: ['./random-enquiry.component.scss']
})
export class RandomEnquiryComponent implements OnInit {
  getCurrentUser:any;
  getUserProfile:any; 
  imageUrl:any;
  nrSelect = '';
  ngFormData:any={};  
  loading = false;
  packageList:any[] = ["Packages"];
  packageCategory:any = [
    { name: 'Comfertable', value: 'Comfertable', checked:false },
    { name: 'Budget', value: 'Budget', checked:false },
    { name: 'Premium', value: 'Premiumwi', checked:false },
    { name: 'Luxury', value: 'Luxury', checked:false }
  ];
  contactType:any= [
    { name: 'By Phone', value: 'By Phone', checked:false},
    { name: 'By E-mail', value: 'By E-mail', checked:false},
    { name: 'By WhatsApp/Text', value: 'By WhatsApp/Text', checked:false}
  ];
  flightBooked:any= [
    { name: 'Yes', value: 'Yes'},
    { name: 'No', value: 'No'},
    { name: 'NA', value: 'NA'}
  ];

  constructor(  
    private apiService : ApiService, 
    private serviceFactory: ServiceFactory,
    private dataFactory: DataFactoryService, 
    private router: Router,
    public dialogRef: MatDialogRef<RandomEnquiryComponent>, 
    @Inject(MAT_DIALOG_DATA) public matDialogData: any) {
      this.getCurrentUser = this.dataFactory.get_CurrentUser(); 
      this.dataFactory.get_Profile().subscribe(res => { 
        if(res) { 
          this.ngFormData['name'] = res.name;
          this.ngFormData['email'] = res.email;
          this.ngFormData['phone_number'] = res.phone; 
        }
        
     }) 
  }
  onNoClick(){
    this.dataFactory.setCookie( 'pop_close', JSON.stringify(1),365);  
  }
  
  ngOnInit() {

  }

 getKey(veg:any){
  
    const arr:any = [];
    Object.keys(veg).forEach(function(item){ 
      if(veg[item]) arr.push(item);
    }); 
    return arr;
  }

  onSubmit(Form_Group:any){
     
     
      
    // stop here if form is invalid 
    if (Form_Group.invalid) {
        return;
    }
    let elmForm = Form_Group.value; 
 
 
 
   this.serviceFactory.loadingStart("body","Please wait while loading...","");
   this.loading = true;
    this.apiService.postData('xh-send-enquiry',elmForm,'formData').pipe(  
      finalize(() => {  
     this.serviceFactory.loadingStop("body","");
      })
    ).subscribe((res:any) => {  
      if(res.success){ 
        this.serviceFactory.notification('Your request have been submitted.',true); 
        this.dialogRef.close();
        this.loading = false;
        this.router.navigate(['/thank-you']);
      }else{
        this.serviceFactory.notification(res.data.message,res.success);
      }
       
     }) 
     
  
     
     
 }

 

 opencustom(){
   
 }

  
  hasErrorPhone(name:any,hasAction:any,formElm:any){ 
    
    if(!hasAction){
      formElm.form.controls[name].setErrors({'incorrect': true});
    }else{
      formElm.form.controls[name].setErrors(null);
    } 
  }
  getEventVal(event:any){
    
     return event.target.value
  }
  }
  

