import { Directive, HostListener } from '@angular/core';
import {Location} from '@angular/common';
import {  NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs'; 
@Directive({
  selector: '[appLocationBack]'
})
export class LocationBackDirective {
  public urlChanged = new Subject();
  redirect: any;
  constructor(
    private location: Location,
    private router: Router, 
  ) {
 debugger
    router.events.subscribe((val:any) => {
      // see also 
      return val['url']=="/" && val['urlAfterRedirects']=="/"? false:true 
      
   });

  location.onUrlChange((url, state) => {
    this.urlChanged.next({ url, state });
     

    
  });
   } 

   
 
  @HostListener('click', ['$event.target'])
  onClick(event:any) {
    debugger
    this.location.back();
 }

}