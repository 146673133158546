import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginAuthGuard } from './auth/login-auth-guard.service';
import { DaffExternalRouterExistenceGuard } from '@daffodil/external-router/routing';
import { DaffExternalRouterDriverTestingModule } from '@daffodil/external-router/driver/testing';
import { DaffExternalRouterModule } from '@daffodil/external-router';

const staticUrl = [
  "about-us",
  "career",
  "our-team",
  "testimonials",
  "contact-us"

] 


const routes: Routes = [
  { path: '', loadChildren: () => import(`./pages/home/home.module`).then(m => m.HomeModule)},
  { path: 'customers/login', canActivate: [LoginAuthGuard], loadChildren: () => import(`./auth/login/login.module`).then(m => m.LoginModule)},
  { path: 'verify-account', canActivate: [LoginAuthGuard], loadChildren: () => import(`./auth/login/login.module`).then(m => m.LoginModule)},
  /***** User *****/
  { path: 'customers/profile/:new', canActivate: [AuthGuard], loadChildren: () => import(`./user/profile/profile.module`).then(m => m.ProfileModule)},
  //{ path: 'booking-process', canActivate: [AuthGuard], loadChildren: () => import(`./user/check-out/check-out.module`).then(m => m.CheckOutModule)},
  { path: 'pay', loadChildren: () => import(`./pages/pay/pay.module`).then(m => m.PayModule)},
  //{ path: 'sitemap', loadChildren: () => import(`./pages/sitemap/sitemap.module`).then(m => m.SitemapModule)},
  { path: 'thank-you', loadChildren: () => import(`./pages/thank-you/thank-you.module`).then(m => m.ThankYouModule)},
  { path: 'testimonials', loadChildren: () => import(`./pages/reviews/reviews.module`).then(m => m.ReviewsModule)},
  //{ path: 'weather/:slug', loadChildren: () => import(`./pages/weather/weather.module`).then(m => m.WeatherModule)},
  { path: 'blogs', loadChildren: () => import(`./pages/blog/blog.module`).then(m => m.BlogModule)},
  { path: 'blog/:slug', loadChildren: () => import(`./pages/blog/blog.module`).then(m => m.BlogModule)},
  { path: 'blogs/:slug', loadChildren: () => import(`./pages/blog/blog-details/blog-details.module`).then(m => m.BlogDetailsModule)},
  { path: 'blogs/:category/:slug', loadChildren: () => import(`./pages/blog/blog-details/blog-details.module`).then(m => m.BlogDetailsModule)},
  //{ path: 'faqs/:slug', loadChildren: () => import(`./pages/faqs/faqs.module`).then(m => m.FaqsModule)},
  //{ path: 'faqs/:slug/:category', loadChildren: () => import(`./pages/faqs/faqs-details/faqs-details.module`).then(m => m.FaqsDetailsModule)},
  { path: 'emailverify', loadChildren: () => import(`./pages/verify/verify.module`).then(m => m.VerifyModule)},
  { path: 'india-tour', loadChildren: () => import(`./pages/zone-tour/home.module`).then(m => m.HomeModule)},
  { path: 'international-tour', loadChildren: () => import(`./pages/international-tour/home.module`).then(m => m.HomeModule)},
  { path: 'continents/:category', loadChildren: () => import(`./pages/international-tour copy/home.module`).then(m => m.HomeModule)},

  { path: 'services/:new', loadChildren: () => import(`./pages/services/home.module`).then(m => m.HomeModule)},

  { path: 'theme', loadChildren: () => import(`./pages/package-listing/package-listing.module`).then(m => m.PackageListingModule)},
  { path: 'theme/:category', loadChildren: () => import(`./pages/package-listing/package-listing.module`).then(m => m.PackageListingModule)},
  { path: 'special-offers/:slug', loadChildren: () => import(`./pages/landing-page/landing.module`).then(m => m.LandingModule)},

  { path: 'our-company/:slug', loadChildren: () => import(`./pages/custom/custom.module`).then(m => m.CustomModule)},
  { path: 'package', loadChildren: () => import(`./pages/package-listing/package-listing.module`).then(m => m.PackageListingModule)},
  { path: 'package/:category', loadChildren: () => import(`./pages/package-listing/package-listing.module`).then(m => m.PackageListingModule)},

  { path: ':new/:id', loadChildren: () => import(`./pages/package-detail/package-detail.module`).then(m => m.PackageDetailModule)},


  {path: '404',  loadChildren: () => import(`./pages/home/home.module`).then(m => m.HomeModule)},


  {
    path: '**',
    canActivate: [DaffExternalRouterExistenceGuard],
    loadChildren: () => import(`./pagenotfound/pagenotfound.module`).then(m => m.PagenotfoundModule )
  },
 

];

// {
//   'blog-post-1': 'BLOG_POST',
// }



function bindStaticRoute(){
 let newRout:any = {};
  staticUrl.forEach(x => { 
    newRout[x] ='db-page';
  }); 
  return newRout;
}

@NgModule({
  imports: [
    DaffExternalRouterDriverTestingModule.forRoot(bindStaticRoute()),
    DaffExternalRouterModule.forRoot(
      {
        failedResolutionPath: '404',
        notFoundResolutionPath: '404',
      },
      [
        {
          type: 'db-page',
          route: {
            loadChildren: () => import(`./pages/custom/custom.module`).then(m => m.CustomModule)
          },
        },
      ]
    ),
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
