import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RandomEnquiryComponent } from 'src/app/shared/random-enquiry/random-enquiry.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2'
  

@Injectable({
  providedIn: 'root'
})
export class ServiceFactory {
  tokenFromUI: string = "0123456789123456";
  activeElm:any; 
  isBrowser:any;
  constructor(
    public dialog: MatDialog,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: string 
  ) { 
    
  }

 
 
  randomEnquiry(time?:any) {

   // if(isPlatformBrowser(this.platformId) && environment.production){
      // setTimeout(()=>{ 
      //   const dialogRef = this.dialog.open(RandomEnquiryComponent, {
      //     maxWidth: '450px',
      //     width: '450px',
      //     disableClose: true,
      //     autoFocus: false,
          
      //      //scrollStrategy: new NoopScrollStrategy(),
      //     panelClass: "enqyiry-custom-modal",
      //   });
      // }, time?time:2000);
    // }
  }

 // # Region notification
   // # Region notification
  notification(message: string, type: any) {
    //debugger
     this.activeElm = this.doc.activeElement;
    
    let messageType:any
    if(type==true){
      messageType ='success';
    }else if(type==false){
      messageType ='error';
    }else{
       messageType = type
    } 

   // messageType = (type ? 'success' : 'error');   
    Swal.fire({
      toast: true,
      position: 'bottom',
      icon: messageType, 
      title: message,
      showConfirmButton: false,
      customClass: {
        container: 'notification',
        popup: messageType,
      },
      timer: 3000
    });
    //debugger
    if(this.activeElm && this.activeElm!=null){
      this.activeElm.focus()
    }
     
  }
  //# End - Region notification


  // # Region Loader

  loadingStart(elm: any, msg: string, btn: string) {
   // debugger  
    let ifLoading=this.doc.querySelector('#loading-'+elm.slice(1)+'-overlay');
    if(ifLoading!=null){
      ifLoading.remove();
    }
    let target=this.doc.querySelector(elm);
    if(target!=null){
        target.style.position = "relative";         
        var wrapper = this.doc.createElement('div');
        wrapper.className = 'loading-overlay-wrapper';
        wrapper.id = 'loading-'+elm.slice(1)+'-overlay';    
        
        var container = this.doc.createElement('div');
        container.className = 'loading-container';
        container.innerHTML = msg;
        wrapper.appendChild(container);
        
        var icon = this.doc.createElement('i');
        icon.className = 'loading-spinner'; 
        container.appendChild(icon);    

        target.appendChild(wrapper);  
    }
    
   
  }

  loadingStop(elm: string, btn: string) {
  //  debugger
    let removeElm=this.doc.querySelector('#loading-'+elm.slice(1)+'-overlay');
    if(removeElm!=null){
      removeElm.remove();
    }

  }

  //# End - Region Loader




  msgPop(msg:any,type:any) {  
    //debugger  
    Swal.fire({
      buttonsStyling: false, 
      allowOutsideClick:false,
      showCloseButton: false, 
      customClass: { 
        container: 'modal-yes-no swal-msg-type',
        popup: 'modalBG',
        actions: 'modal-btn-yes-no mb-4',
        closeButton: 'btn-modal-dismiss',
        icon: 'modal-icon-top',
        confirmButton: 'mat-raised-button mat-button-base mat-primary', 
      },
        title:type,	
        html: msg,
        icon:type,
      }) 
 }



 
 



 fileFalidation(file:any,size:number,exts:any){ 
  debugger 
  let validFile = false;

 var fileSize = file.size/1024/1024;	  
 if(fileSize > size){
     this.msgPop('Maximum file size should be '+size+' MB.','error');
    validFile = false;
    return false
  }

  

  if (file) {
    var get_ext = file.name.split('.'); 
      get_ext = get_ext.reverse();  
      if ( exts.some(function(el: any){ return el === get_ext[0]})){
       // debugger
       validFile = true;
          return true;
          
    }else{
       this.msgPop('File format is not supported. <br>Please select &nbsp; ('+exts.join(', .')+') &nbsp; file  only.','error');
       validFile = false;
       return false
    }
  }
  return validFile
  
}

getFileNameByPath(ngModel:any){
  if(ngModel && ngModel!=''){  
     return ngModel.replace(/^.*[\\\/]/, '')
  }
   return null
}

}
