import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class DataFactoryService {
  isBrowser:any;
  allBank:any ={
    "Kotak":[],
    "IndusInd":[],
    "ICICI":[],
    "Axis":[],
    "Yes":[],
    "HDFC":[],
    "RBL":[],
    "Corporation":[],
    "Baroda":[],
    "HSBC":[],
    "IDBI":[],
    "Standard":[]
  }
  bankRates:any={
    Kotak:{
      3:.12 / 12,
      6:.12 / 12,
      9:.14 / 12,
      12:.14 / 12,
      18:.15 / 12,
      24:.15 / 12,
    },
      IndusInd:{
      3:.13 / 12,
      6:.13 / 12,
      9:.13 / 12,
      12:.12 / 12,
      18:.12 / 12,
      24:.12 / 12,
      36:.12 / 12,
    },
     ICICI:{
      3:.13 / 12,
      6:.14 / 12,
      9:.14 / 12,
      12:.14 / 12,
    },
     Axis:{
      3:.13 / 12,
      6:.13 / 12,
      9:.14 / 12,
      12:.14 / 12,
    },
     Yes:{
      3:.13 / 12,
      6:.13 / 12,
      9:.14 / 12,
      12:.14 / 12,
      18:.15 / 12,
      24:.15 / 12,
    },
     RBL:{
      3:.13 / 12,
      6:.14 / 12,
      9:.15 / 12,
      12:.15 / 12,
      18:.15 / 12,
      24:.15 / 12,
    },
     Corporation:{
      6:.16 / 12,
      12:.18 / 12,
      24:.18 / 12,
    },
     Baroda:{
      3:.13 / 12,
      6:.13 / 12,
      9:.13 / 12,
      12:.13 / 12,
      18:.15 / 12,
      24:.15 / 12,
      36:.15 / 12,
    },
     HSBC:{
      3:.12 / 12,
      6:.12 / 12,
      9:.13 / 12,
      12:.13 / 12,
      18:.13 / 12,
    },
     IDBI:{
      3:.13 / 12,
      6:.13 / 12,
      9:.13 / 12,
      12:.13 / 12,
    },
     Standard:{
      3:.13 / 12,
      6:.13 / 12,
      9:.14 / 12,
      12:.14 / 12,
      18:.15 / 12,
      24:.15 / 12,
    },
  }

   
  private storeData:any = {}; 
  private pageTitle = new BehaviorSubject<any>({});
 
  private userProfile = new BehaviorSubject<any>(null);
  
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    //debugger
    this.isBrowser = isPlatformBrowser(platformId); 
  }

  get_CurrentUser(){
    //debugger 
    let getCookie = this.getCookie('xeniumholidays_user_data'); 
    if(getCookie){   
      return JSON.parse(getCookie)
    } 
    return null;  
  }

  setPageTitle(data:any) {
    this.pageTitle.next(data);
  }

  getPageTitle() {
    return this.pageTitle.asObservable(); 
  }

  
  public set_data(key:any,value:any) {  
    this.storeData[key] = value;
  }

  public get_data(key:any) {  
    return this.storeData[key];
  }

  public delete_key_data(key:any) {  
    return delete this.storeData[key]
  }
  
 
   
  
  set_Profile(data:any) {
    this.userProfile.next(data);
  }

  get_Profile() {
    return this.userProfile.asObservable(); 
  }

 
  
      
setCookie(name:any, value:any, daysToLive:any) {
  var cookie = name + "=" + encodeURIComponent(value);
  if(typeof daysToLive === "number" && this.isBrowser) {
      cookie += "; max-age=" + (daysToLive*24*60*60);
      cookie += "; path=/";
       this.doc.cookie = cookie;
  }
}

getCookie(name:any) {
if(this.isBrowser){
  var cookieArr = this.doc.cookie.split(";");
  for(var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if(name == cookiePair[0].trim()) {
          return decodeURIComponent(cookiePair[1]);
      }
  }
}

  return null;
}
deleteCookie(name:any) {
if(this.isBrowser){
  this.doc.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
 
}

getLocation() {
  if (this.isBrowser) {
    if(navigator.geolocation){
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoError, options);
    }
    
  }
}

geoSuccess(pos:any) {
  var crd = pos.coords;

  console.log('Your current position is:');
  console.log(`Latitude : ${crd.latitude}`);
  console.log(`Longitude: ${crd.longitude}`);
  console.log(`More or less ${crd.accuracy} meters.`);
}

geoError(err:any) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}
  
}

