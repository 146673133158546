
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable({
    providedIn: 'root'
  })
export class LoginAuthGuard implements CanActivate {


  constructor(private apiService: ApiService, private _router: Router) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.apiService.isLoggedIn()) {
      return true;
    }

    // navigate to login page
    this._router.navigate(['/customers/profile/my-account']);
    // you can save redirect url so after authing we can move them back to the page they requested
    return false;
  }

}