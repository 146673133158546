import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[strongPassword]',
  providers: [{provide: NG_VALIDATORS, useExisting: StrongPasswordDirective, multi: true}]
})
export class StrongPasswordDirective implements Validator {
 
  

  constructor(private el: ElementRef){
    // debugger
    
  }
  
  validate(control: FormControl){
     //debugger
    let value: string = control.value || '';

    if (!value) {
      return null
    }
  
    let upperCaseCharacters = /[A-Z]+/g
    if (upperCaseCharacters.test(value) === false) {
      return { passwordStrength: `Please enter one upper case characters.` };
    }
  
    let lowerCaseCharacters = /[a-z]+/g
    if (lowerCaseCharacters.test(value) === false) {
      return { passwordStrength: `Please enter one lower case characters.` };
    }
  
    let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
    if (specialCharacters.test(value) === false) {
      return { passwordStrength: `Please enter one special character.` };
    }
  
    let numberCharacters = /[0-9]+/g
    if (numberCharacters.test(value) === false) {
      return { passwordStrength: `Please enter one number.` };
    }
  
  
  
      let min = /^.{8,16}$/
    if (min.test(value) === false) {
      return { passwordStrength: `Please enter min 8 and max 16 characters.` };
    }
  
   
  
    return null;
  }
 


  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
   // debugger
     
       
   }
}