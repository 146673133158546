

 


      <div class="customised-request">
        <img src="../../../assets/img/new-year-top-text.svg">
        <h1>UpTo 50% OFF</h1>
<p>Book your New Year Holidays Package at best rates. <br>Call us & plan your trip now.!
</p>

         <div class="fix-rate-popup-inner">
            <div class="fix-rate-popup-container">
                  <form #ngElmSendRequest="ngForm" (ngSubmit)="onSubmit(ngElmSendRequest)" class="footer_customised_form">
                     <div class="row  p-2">
                        <div class="col-md-6  pl-2 pr-2">
                          <div class="form-group new_form_des"> 
                                  <mat-form-field appearance="outline" class="d-block">
                                     <input matInput required placeholder="Name" name="name" [(ngModel)]="ngFormData['name']">  
                                  </mat-form-field> 
                           </div>
                        </div>
                        <div class="col-md-6 pl-2 pr-2">
                             <div class="form-group new_form_des"> 
                                     <mat-form-field appearance="outline" class="d-block">
                                        <input matInput required placeholder="Email" name="email" [(ngModel)]="ngFormData['email']" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$">  
                                     </mat-form-field> 
                              </div>
                           </div>
                        <div class="col-md-12  pl-2 pr-2">
                             <div class="form-group new_form_des"> 
                                     <mat-form-field appearance="outline" class="d-block"> 
                                        <input matInput placeholder="Phone Number" required name="phone_number" 
                                         type="tel"
                                         gktTelInput   
                                         [gktTelInputOptions]="{initialCountry: 'in'}"
                                         (hasError)="hasErrorPhone('phone_number', $event,ngElmSendRequest)" 
                                         (blur)="ngFormData['phone_number']= getEventVal($event)"
                                         autocomplete="off" 
                                         [(ngModel)]="ngFormData['phone_number']"> 
                                     </mat-form-field> 
                              </div>
                           </div>
                           </div>
                        
                           <button class="f_btn" [class.spinner]="loading" [disabled]="loading" mat-raised-button color="warn">Submit</button>
                           <a href="javascript:void(0)"  mat-dialog-close tabindex="-1" (click)="onNoClick()"
                           class="xh-close"></a>
                           
                           
                 </form>
            </div>
         </div>
      </div>
 
