import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[customMax]',
  providers: [{provide: NG_VALIDATORS, useExisting: CustomMaxDirective, multi: true}]
})
export class CustomMaxDirective implements Validator {
  @Input() customMax: number =0;
  @Input() customDecimal?: number;
  @Input() customString?: boolean;
  
 
  private specialKeys: Array < string > = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete']; 

 
  
  constructor(private el: ElementRef){}
  
  
  validate(c: FormControl) {
  //  debugger
      let v = c.value;
      if(this.customString && c.value){
        v = c.value.length
      }

      return ( v > this.customMax)? {"customMax": true} : null;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
   // debugger
     if(this.customDecimal){
          // Allow Backspace, tab, end, and home keys     
          if (this.specialKeys.indexOf(event.key) !== -1) {  
          return;  
          }  
          let regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);  

         // let regex  = /^\d*\.?\d{0,'+Number(this.customDecimal)+'}$/g;  
          let current: string = this.el.nativeElement.value;   
          let next: string =  current.concat(event.key); 
           if ( next && !String(next).match(new RegExp(regex))) {  
             event.preventDefault();
          } 
     } 
       
   }
} 