import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router'; 
import { ApiService } from '../services/api.service';
 
 
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  
  canChild:boolean=false;
  constructor(
     private apiService: ApiService,
     private router: Router
     ) {
     // debugger
     } 

     
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {      
    const url: string = state.url;
    return this.checkLogin(url); 
    
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
     //debugger
    this.canChild =true;
    return this.canActivate(route, state);
  }

  checkLogin(url: string) { 
      //debugger
    
    if (this.apiService.isLoggedIn()) {
       return true
       
    } else{
      this.router.navigate(['./customers/login'], {queryParams: { redirect: url }} );
      return false
    }
     
    //this.commonService.redirectUrl = url;

   
    
  }
}
