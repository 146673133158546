import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { finalize, throwError } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ServiceFactory } from './factory/service-factory.service';
import { DataFactoryService } from './factory/data-factory.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
declare const window: any;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  verifyEmailCode(code: any) {
    throw new Error('Method not implemented.');
  } 
  serverUrl: string; 
  constructor(
    private serviceFactory:ServiceFactory,
    private dataFactory: DataFactoryService, 
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject(DOCUMENT) private doc: Document, 
    ) { 
    this.serverUrl = "https://api.xeniumholidays.com/api/";
    
 

  }

     // get method request
  getData(url:any, params?:any,formData?:any){ 
       //debugger
       let getCurrentUser = this.dataFactory.get_CurrentUser(); 

       let headerDict:any = {}
       if(getCurrentUser){
        headerDict['Authorization'] = getCurrentUser.token;
      }
      if(formData=='formData'){
        headerDict['Content-Type'] =  'application/x-www-form-urlencoded';
     } 
      let requestOptions:any = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
      }; 
      if(params){
        requestOptions['params'] = params
      }
       return this.http.get(this.serverUrl+url, requestOptions)
   }
     // post method request
    postData(url:any, params?:any,formData?:any){ 
       let getCurrentUser = this.dataFactory.get_CurrentUser(); 
       let headerDict:any = {}
       if(getCurrentUser){
        headerDict['Authorization'] = getCurrentUser.token;
      }
    if(formData=='formData'){
       headerDict['Content-Type'] =  'application/x-www-form-urlencoded';
    } 
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
     return this.http.post(this.serverUrl+url, params, requestOptions)
   }

   /*****************/

   isLoggedIn() {   
    //debugger    
    if (this.dataFactory.get_CurrentUser()) { 
      return true;
    }     
    return false;
  }
   
 

  public logout(url:any) {
   
     this.dataFactory.deleteCookie('xeniumholidays_user_data'); 
    // this.dataFactory.set_Profile(null);  
     //this.router.navigateByUrl(url); 
    this.router.navigate(['/']); 

  }

 




  /********************* */
  
  public getPackagesCategory(params:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.get("https://api.xeniumholidays.com/cache/category.json?var=13", { headers: headers }
    );
  }

  public getAllCountryList(params:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.get("https://xeniumholidays.com/country.json?var=1", { headers: headers }
    );
 
  }

   callWebEngage(data:any) {
   const headers = new HttpHeaders({
     'Content-Type': 'application/json',
     'Authorization': "Bearer 9e7b641d-c7d1-4113-b101-4b70c579a231"
    });
    return this.http.post("https://api.in.webengage.com/v1/accounts/in~11b5642c4/users", data, { headers: headers }
    );
  }
  


 public getWeather2(params:any) {
   const headers = new HttpHeaders({
     'Content-Type': 'application/x-www-form-urlencoded'
   });
   return this.http.get("http://api.weatherapi.com/v1/forecast.json?key=64d3a1de3fdd4c43a1985814222012", { headers: headers }
   );
 }
// auto:ip


public getWeather(params:any) {
  const headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });
  return this.http.get("https://pro.openweathermap.org/data/2.5/forecast/climate?lat=28.5355&lon=77.3910&appid=109a0d308be578a1b94e06822040831c", { headers: headers }
  );
}

public sendSMS(params:any) {
  const headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });
  return this.http.post(this.serverUrl + "discountsms", { headers: headers });
}

public call(number:any, pri:any,otp:any) {
  return this.http.get(`https://cloud.paramantra.com/paramantra/click_to_call?pri=${pri}&customer_number=${number}&otp=${otp}`);
}


  public getbestSellerPkg(params:any) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.get("https://api.xeniumholidays.com/cache/best_selling_package.json?var=11", { headers: headers }
    );
  }

  public getPopularBlogDetail(params:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.get("https://api.xeniumholidays.com/cache/most_popular_blog.json?var=2", { headers: headers }
    );
  }

 /******************** */

 login(option:any) {
  // debugger  
  const headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });
   return this.http.post<any>(`${this.serverUrl}login`,option, { headers: headers });
 } 

 forgotPasswordOTPSend(option:any) {
  // debugger 
  const headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });
  this.serviceFactory.loadingStart("body","Please wait while loading...",""); 
   return this.http.post<any>(`${this.serverUrl}userProfile/forgotPasswordOTPSend`,option, { headers: headers }).pipe( 
     finalize(() => { 
     this.serviceFactory.loadingStop("body","");
     })
   );
 } 

 forgotPasswordOTPVerify(option:any) {
  // debugger 
  const headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });
  this.serviceFactory.loadingStart("body","Please wait while loading...",""); 
   return this.http.post<any>(`${this.serverUrl}userProfile/forgotPasswordOTPVerify`,option, { headers: headers }).pipe( 
     finalize(() => { 
     this.serviceFactory.loadingStop("body","");
     })
   );
 }
 
 forgotPasswordUpdate(option:any) {
  // debugger 
  const headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });
  this.serviceFactory.loadingStart("body","Please wait while loading...",""); 
   return this.http.post<any>(`${this.serverUrl}userProfile/forgotPasswordUpdate`,option, { headers: headers }).pipe( 
     finalize(() => { 
     this.serviceFactory.loadingStop("body","");
     })
   );
 }


 
updateNewPassword(params: any ,token: string,uuid: string) { 

  const headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });

  this.serviceFactory.loadingStart("body","Please wait while loading...",""); 
  return this.http.post<any>(this.serverUrl + "setpassword/"+ token+ '/'+uuid, params, { headers: headers }).pipe( 
    finalize(() => { 
    this.serviceFactory.loadingStop("body","");
    })
  );
 
}

 loadProfile(id:any){
  // debugger
  this.getData('xh-user-profile/'+id).subscribe((res:any) => {
    if(res.success){
      let data = res.data[0]; 
      if(!data['profileimage']){
        let img = data['gender']?'no_profile_'+data['gender']+'.svg':'blank.png'
        data['profileimage'] = './assets/images/'+img;
        
      }
      
       this.dataFactory.set_Profile(data); 
    } 
  })  
}

logoutByClick(url:any) {
  //debugger
  Swal.fire({ 
   title: '<h2 matDialogTitle class="modal_title text-center">Logout</h2>',
   html: '<p>Are you sure you want to Logout?</p>', 
  // icon: 'warning', 
   customClass: {
       confirmButton: 'mat-flat-button mat-button-base mat-warn order-last',
       cancelButton: 'mat-stroked-button mat-button-base ',
       container: 'modal-yes-no Modal_Delete', 
       actions: 'modal-btn-yes-no mb-4',
       closeButton: 'btn-modal-dismiss',
     // header: 'pt-4', 
   },
   width: '400px',
   showCloseButton: true,
   buttonsStyling: false,
   showCancelButton: true,
   confirmButtonText: 'Logout',
   cancelButtonText: 'Cancel' ,       
}).then((result) => {
 //debugger 
 if(result.value){  
   this.logout(url);   
  }
}) 

}

}