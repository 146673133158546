import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare const window: any;
const defaultUtilScript = 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.1/js/utils.js';

@Directive({
  selector: '[gktTelInput]',
})
export class GktTelInput implements OnInit {
  @Input('gktTelInputOptions') gktTelInputOptions: { [key: string]: any } = {};
  @Output('hasError') hasError: EventEmitter<boolean> = new EventEmitter();
  @Output('gktTelOutput') gktTelOutput: EventEmitter<any> = new EventEmitter();
  @Output('countryChange') countryChange: EventEmitter<any> = new EventEmitter();
  @Output('intlTelInputObject') intlTelInputObject: EventEmitter<any> = new EventEmitter();
  @Input('gktTelWithHyphen') gktTelWithHyphen: boolean =false;

  

  ngTelInput: any;

  constructor(private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: string) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.gktTelInputOptions = {
        ...this.gktTelInputOptions,
        
       // nationalMode:true,
       autoPlaceholder: false,
      // autoHideDialCode: true,
       formatOnDisplay: false,
       //nationalMode: true,
        utilsScript: this.getUtilsScript(this.gktTelInputOptions),
        // customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
        //   //debugger
        //   return " ";
        //  // return "e.g. " + selectedCountryPlaceholder;
        // },
      };
      this.ngTelInput = window.intlTelInput(this.el.nativeElement, {
        ...this.gktTelInputOptions
      });

      this.el.nativeElement.addEventListener("countrychange", () => {
        this.countryChange.emit(this.ngTelInput.getSelectedCountryData());
      });

      this.intlTelInputObject.emit(this.ngTelInput);
    }
  }

  @HostListener('blur') onBlur() {
    //debugger
    

    let isInputValid: boolean = this.isInputValid();
    if (isInputValid) {
      let telOutput = this.ngTelInput.getNumber();
      this.ngTelInput.setNumber(telOutput);
      let code = this.ngTelInput.getSelectedCountryData();
      
      
      if(this.gktTelWithHyphen){
        var fullNumber = telOutput.replace('-', '');  
        var getCode = code.dialCode; 
        var onlyNumber = fullNumber.replace('+'+getCode, ''); 
        this.ngTelInput.setNumber('+' + getCode+'-'+onlyNumber);
       // this.el.nativeElement.value = '+' + getCode+'-'+onlyNumber;
        //this.hasError.emit(isInputValid);
       // this.gktTelOutput.emit('+' + getCode+'-'+onlyNumber);
      }else{
        this.ngTelInput.setNumber(telOutput);
        //this.el.nativeElement.value = telOutput;
        this.hasError.emit(isInputValid);
        this.gktTelOutput.emit(telOutput);
      }
      this.countryChange.emit(this.ngTelInput.getSelectedCountryData());
     
      
      
      
    } else {
      this.hasError.emit(isInputValid);
    }
    
  }

  isInputValid(): boolean {
    return this.ngTelInput.isValidNumber();
  }

  setCountry(country: any) {
    this.ngTelInput.setCountry(country);
  }

  getUtilsScript(options: any) {
    return options.utilsScript || defaultUtilScript;
  }
}