import {NgModule} from '@angular/core';

import { CommonModule } from '@angular/common'; 
 
import { GktTelInput } from 'src/app/directive/phone-input.directive';
import { LocationBackDirective } from 'src/app/directive/location-back.directive'; 
import { CustomMaxDirective } from 'src/app/directive/custom-max-validator.directive';  
import { StrongPasswordDirective } from './directive/strong-password-validator.directive';
import { MustMatchDirective } from './directive/must-match.directive';


 
@NgModule({
    imports:[CommonModule],
    exports: [
     GktTelInput,
     LocationBackDirective, 
     CustomMaxDirective, 
     StrongPasswordDirective,
     MustMatchDirective  
    ],
    declarations: [
     GktTelInput,
     LocationBackDirective, 
     CustomMaxDirective, 
     StrongPasswordDirective,
     MustMatchDirective
    ]
  })
  export class SharedModule {}