import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'xeniumholidays';
  href: any;
  getUserProfile: any;
  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.updateToLatest();
  }

  updateToLatest(): void {
    //alert('updateToLatest');

    const updatesAvailable = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map((evt) => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))
    );

    console.log('updatesAvailable==' + updatesAvailable);

    if (!this.swUpdate.isEnabled) {
      console.log('swUpdate Not isEnabled !!');
      return;
    }

    this.swUpdate.available.subscribe((event) => {
      console.log('current version is - ', event.current);
      console.log('available version is - ', event.available);

      this.swUpdate.activateUpdate().then(() => this.doc.location.reload());
    });

    this.swUpdate.activated.subscribe((event) => {
      console.log('previous previous is - ', event.previous);
      console.log('current version is - ', event.current);
    });

    //this.swUpdate.activateUpdate().then(() => window.location.reload());
  }

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.router.events.subscribe((event: any) => {
      //debugger

      if (event instanceof NavigationEnd) {
        // if (isPlatformBrowser(this.platformId)) {
        //   console.log("document.title2=============="+document.title)
        // }
        // if (isPlatformBrowser(this.platformId)) {
        //   gtag('event', 'page_view', {
        //     page_path: event.urlAfterRedirects,
        //     send_to: 'UA-57647446-1',
        //   });
        // }
      }
    });
  }

  onActivate(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }
}
